<aside>
  <nav>
    <ul>
      <li *ngIf="!isSafeGis && isGpaUser || isGpaGeomonitoringAdmin">
        <div
          class="arrow"
          (click)="toggleArrowSide()"
          [ngStyle]="{ left: isSidebarPinned ? '22px' : '208px' }"
        >
          <svg
            [style.transform]="rotationArrow()"
            width="16"
            height="15"
            viewBox="0 0 16 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14.8647 0.00176811C15.1295 0.0013113 15.3861 0.0826864 15.59 0.231768C15.7048 0.315717 15.7996 0.418818 15.8692 0.535166C15.9387 0.651513 15.9815 0.778819 15.9952 0.909796C16.0089 1.04077 15.9932 1.17284 15.9489 1.29845C15.9047 1.42405 15.8328 1.54072 15.7374 1.64177L10.6603 7.00177L15.556 12.3718C15.6502 12.4741 15.7205 12.5918 15.7629 12.7181C15.8053 12.8444 15.819 12.9769 15.8032 13.108C15.7874 13.239 15.7424 13.366 15.6708 13.4816C15.5992 13.5972 15.5025 13.6992 15.386 13.7818C15.2688 13.8728 15.1315 13.9415 14.9827 13.9835C14.834 14.0255 14.677 14.0399 14.5216 14.0258C14.3663 14.0117 14.2159 13.9694 14.0799 13.9016C13.9439 13.8338 13.8253 13.7419 13.7315 13.6318L8.2578 7.63177C8.09112 7.45284 8 7.22839 8 6.99677C8 6.76514 8.09112 6.5407 8.2578 6.36177L13.9241 0.361769C14.0378 0.240751 14.1822 0.145087 14.3457 0.0825386C14.5091 0.01999 14.6869 -0.0076828 14.8647 0.00176811Z"
              fill="url(#paint0_linear_341_122)"
            />
            <path
              d="M6.86474 0.00176811C7.12953 0.0013113 7.38614 0.0826864 7.59003 0.231768C7.70478 0.315717 7.79964 0.418818 7.86917 0.535166C7.93869 0.651513 7.98153 0.778819 7.99521 0.909796C8.0089 1.04077 7.99317 1.17284 7.94892 1.29845C7.90467 1.42405 7.83278 1.54072 7.73736 1.64177L2.66033 7.00177L7.55603 12.3718C7.65017 12.4741 7.72047 12.5918 7.76289 12.7181C7.80531 12.8444 7.81901 12.9769 7.80322 13.108C7.78742 13.239 7.74243 13.366 7.67084 13.4816C7.59925 13.5972 7.50246 13.6992 7.38604 13.7818C7.26879 13.8728 7.13147 13.9415 6.98272 13.9835C6.83396 14.0255 6.67698 14.0399 6.52162 14.0258C6.36625 14.0117 6.21587 13.9694 6.0799 13.9016C5.94392 13.8338 5.8253 13.7419 5.73147 13.6318L0.257804 7.63177C0.0911217 7.45284 0 7.22839 0 6.99677C0 6.76514 0.0911217 6.5407 0.257804 6.36177L5.92413 0.361769C6.03782 0.240751 6.18223 0.145087 6.34566 0.0825386C6.50909 0.01999 6.68692 -0.0076828 6.86474 0.00176811Z"
              fill="url(#paint1_linear_341_122)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_341_122"
                x1="15.8251"
                y1="0.20834"
                x2="0.690979"
                y2="14.7237"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#487513" />
                <stop offset="1" stop-color="#BADF69" />
              </linearGradient>
              <linearGradient
                id="paint1_linear_341_122"
                x1="15.8251"
                y1="0.20834"
                x2="0.690979"
                y2="14.7237"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#487513" />
                <stop offset="1" stop-color="#BADF69" />
              </linearGradient>
            </defs>
          </svg>
        </div>
      </li>


      <li *ngIf="(!isSafeGis && isGpaUser || isGpaGeomonitoringAdmin) && !isNicePlanet">
        <a
          routerLink="/home"
          routerLinkActive="active"
          ariaCurrentWhenActive="page"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          <span class="icon">
            <svg
              width="26"
              height="24"
              viewBox="0 0 26 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M24.8809 11.8086L13.618 0.553933C13.5369 0.472678 13.4406 0.408214 13.3345 0.364231C13.2285 0.320247 13.1148 0.297607 13 0.297607C12.8852 0.297607 12.7716 0.320247 12.6655 0.364231C12.5595 0.408214 12.4632 0.472678 12.3821 0.553933L1.11917 11.8086C0.79104 12.1367 0.605103 12.5824 0.605103 13.0473C0.605103 14.0125 1.38987 14.7973 2.3551 14.7973H3.54182V22.8282C3.54182 23.3121 3.93284 23.7032 4.41682 23.7032H11.25V17.5782H14.3125V23.7032H21.5832C22.0672 23.7032 22.4582 23.3121 22.4582 22.8282V14.7973H23.6449C24.1098 14.7973 24.5555 14.6141 24.8836 14.2832C25.5645 13.5996 25.5645 12.4922 24.8809 11.8086Z"
                fill="white"
              />
            </svg>
          </span>
          <span class="item">Home</span>
        </a>
      </li>

      <li *ngIf="(!isSafeGis && isGpaUser && !isCarrefour && !isAuditing) || isNicePlanet">
        <a routerLink="/relatorios" [routerLinkActive]="['active']">
          <span class="icon">
            <svg
              width="30"
              height="30"
              viewBox="0 0 30 30"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_341_641)">
                <path d="M28 27H3V2H5.41934V24.5807H28V27Z" fill="white" />
                <path
                  d="M10.258 14.0968H7.83862V22.1613H10.258V14.0968Z"
                  fill="white"
                />
                <path
                  d="M15.0966 7.64514H12.6772V22.1613H15.0966V7.64514Z"
                  fill="white"
                />
                <path
                  d="M19.9354 14.0968H17.5161V22.1613H19.9354V14.0968Z"
                  fill="white"
                />
                <path
                  d="M24.7741 7.64514H22.3547V22.1613H24.7741V7.64514Z"
                  fill="white"
                />
                <path d="M28 24.5807H16.3064V27H28V24.5807Z" fill="white" />
              </g>
              <defs>
                <clipPath id="clip0_341_641">
                  <rect
                    width="25"
                    height="25"
                    fill="white"
                    transform="translate(3 2)"
                  />
                </clipPath>
              </defs>
            </svg>
          </span>
          <span class="item">Relatórios</span>
        </a>
    
    
    </ul>
  </nav>
  <img
  src='../../../assets/images/logo-assai.png'
  alt=""
  class="nav-image"
/>
</aside>