import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { filter } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TraceabilityService {

  storage: Storage;

  constructor(private http: HttpClient) {
    this.storage = window.sessionStorage;
  }

  getListPaginated(filters: {
    startDate: any,
    endDate: any,
    productType: string,
    economicGroup: string,
    customerDocument?: string,
    productHash?: string,
    operationalType?: string;
    code?: string,
    carrefour?:boolean,
    invoiceNumber?: string,
    identifier?: string,
    sif?: string,
    car?: string,
    providerDocument?: number,
    page: number,
    size: number,
    documentDelivery?: number,
  }) {
    const item = this.storage.getItem('token');
    const token = item ? JSON.parse(item) : {};
    let http = {};

    const userItem = this.storage.getItem('user');
    const user = userItem ? JSON.parse(userItem) : {};

    let isUserProvider = null;

    if(user.authorities.some((item: string) => item === "ROLE_PROVIDER")){
      isUserProvider = true;
    }

    let params = new HttpParams()
      .set('page', filters.page)
      .set('size', filters.size)
      .set('economicGroup', filters.economicGroup != "undefined" ? filters.economicGroup : "")

    if (filters.productHash) {
      params = params.set('productHash', filters.productHash);
    }

    if (filters.operationalType) {
      params = params.set('operationalType', filters.operationalType);
    }

    if (filters.customerDocument) {
      params = params.set('customerDocument', filters.customerDocument);
    }
    if (filters.providerDocument) {
      params = params.set('providerDocument', filters.providerDocument);
    }

    if (filters.productType && isUserProvider == null) {
      params = params.set('productType', filters.productType);
    }

    if (filters.startDate) {
      params = params.set('startDate', filters.startDate);
    }

    if (filters.endDate) {
      params = params.set('endDate', filters.endDate);
    }

    if (filters.identifier) {
      params = params.set('identifier', filters.identifier);
    }

    if (filters.car) {
      params = params.set('ruralEnvironmentalRegistry', filters.car);
    }

    if (filters.sif) {
      params = params.set('federalInspectionSeal', filters.sif);
    }

    if (filters.documentDelivery) {
      params = params.set('customerDocument', filters.documentDelivery);
    }

    if (filters.invoiceNumber) {
      params = params.set('invoiceNumber', filters.invoiceNumber);
    }
    let path='';
    if(filters.carrefour){
      path="/carrefour";
    }

    if (token) {
      http = {
        headers: new HttpHeaders({
          Authorization: token,
        }),
        params,
      };
    }

    return this.http.get(`${environment.api}/movement-service/getMovementsForProductFromOriginToDestiny${path}`, http).pipe(
      res => res,
      error => error
    );
  }

  getListPaginatedCahe(filters: {
    startDate: any,
    endDate: any,
    productType: string,
    economicGroup: string,
    customerDocument?: string,
    productHash?: string,
    operationalType?: string;
    code?: string,
    evaluator?: string,
    carrefour?:boolean,
    invoiceNumber?: string,
    identifier?: string,
    sif?: string,
    car?: string,
    providerDocument?: number,
    page: number,
    size: number,
    documentDelivery?: number,
  }) {
    const item = this.storage.getItem('token');
    const token = item ? JSON.parse(item) : {};
    let http = {};

    const userItem = this.storage.getItem('user');
    const user = userItem ? JSON.parse(userItem) : {};

    let isUserProvider = null;

    if(user.authorities.some((item: string) => item === "ROLE_PROVIDER")){
      isUserProvider = true;
    }

    let params = new HttpParams()
      .set('page', filters.page)
      .set('size', filters.size)
      .set('economicGroup', filters.economicGroup != "undefined" ? filters.economicGroup : "")

    if (filters.productHash) {
      params = params.set('productHash', filters.productHash);
    }
    if (filters.evaluator) {
      params = params.set('evaluator', filters.evaluator);
    }
    if (filters.operationalType) {
      params = params.set('operationalType', filters.operationalType);
    }

    if (filters.customerDocument) {
      params = params.set('customerDocument', filters.customerDocument);
    }
    if (filters.providerDocument) {
      params = params.set('providerDocument', filters.providerDocument);
    }

    if (filters.productType && isUserProvider == null) {
      params = params.set('productType', filters.productType);
    }

    if (filters.startDate) {
      params = params.set('startDate', filters.startDate);
    }

    if (filters.endDate) {
      params = params.set('endDate', filters.endDate);
    }

    if (filters.identifier) {
      params = params.set('identifier', filters.identifier);
    }

    if (filters.car) {
      params = params.set('ruralEnvironmentalRegistry', filters.car);
    }

    if (filters.sif) {
      params = params.set('federalInspectionSeal', filters.sif);
    }

    if (filters.documentDelivery) {
      params = params.set('customerDocument', filters.documentDelivery);
    }

    if (filters.invoiceNumber) {
      params = params.set('invoiceNumber', filters.invoiceNumber);
    }
    let path='';
    if(filters.carrefour){
      path="/carrefour";
    }

    if (token) {
      http = {
        headers: new HttpHeaders({
          Authorization: token,
        }),
        params,
      };
    }

    return this.http.get(`${environment.api}/cache/filtered-movements`, http).pipe(
      res => res,
      error => error
    );
  }

  exportListPaginated(filters: {
    startDate: any,
    endDate: any,
    productType: string,
    economicGroup: string,
    customerDocument?: string,
    productHash?: string,
    code?: string,
    carrefour?:boolean,
    invoiceNumber?: string,
    identifier?: string,
    sif?: string,
    car?: string,
    providerDocument?: number,
    page: number,
    size: number,
    documentDelivery?: number,
  }) {
    const item = this.storage.getItem('token');
    const token = item ? JSON.parse(item) : {};
    let http = {};

    const userItem = this.storage.getItem('user');
    const user = userItem ? JSON.parse(userItem) : {};

    let isUserProvider = null;

    if(user.authorities.some((item: string) => item === "ROLE_PROVIDER")){
      isUserProvider = true;
    }

    let params = new HttpParams()
      .set('economicGroup', filters.economicGroup != "undefined" ? filters.economicGroup : "")

    if (filters.productHash) {
      params = params.set('productHash', filters.productHash);
    }

    if (filters.customerDocument) {
      params = params.set('customerDocument', filters.customerDocument);
    }
    if (filters.providerDocument) {
      params = params.set('providerDocument', filters.providerDocument);
    }

    if (filters.productType && isUserProvider == null) {
      params = params.set('productType', filters.productType);
    }

    if (filters.startDate) {
      params = params.set('startDate', filters.startDate);
    }

    if (filters.endDate) {
      params = params.set('endDate', filters.endDate);
    }

    if (filters.identifier) {
      params = params.set('identifier', filters.identifier);
    }

    if (filters.car) {
      params = params.set('ruralEnvironmentalRegistry', filters.car);
    }

    if (filters.sif) {
      params = params.set('federalInspectionSeal', filters.sif);
    }

    if (filters.documentDelivery) {
      params = params.set('customerDocument', filters.documentDelivery);
    }

    if (filters.invoiceNumber) {
      params = params.set('invoiceNumber', filters.invoiceNumber);
    }

    if (token) {
      http = {
        headers: new HttpHeaders({
          Authorization: token,
        }),
        params,
      };
    }

    let httpRequest : any = http;
    httpRequest.responseType = 'text';

    return this.http.get(`${environment.api}/movementXLSX-service/getMovementsForProductFromOriginToDestiny`, httpRequest).pipe(
      res => res,
      error => error
    );
  }

  public getTraceabilityReports(startDate: string, endDate: string, productType: string, optionsTraceability: any) {
    const item = this.storage.getItem('token');
    const token = item ? JSON.parse(item) : {};

    const userItem = this.storage.getItem('user');
    const user = userItem ? JSON.parse(userItem) : {};

    let optionsReports: any = [];

    optionsTraceability.provider ? optionsReports.push("provider") : null;
    optionsTraceability.provider ? optionsReports.push("providerCode") : null,
      optionsTraceability.provider ? optionsReports.push("providerName") : null,
      optionsTraceability.customer ? optionsReports.push("customer") : null,
      optionsTraceability.productType ? optionsReports.push("productType") : null,
      optionsTraceability.completionDate ? optionsReports.push("completionDate") : null,
      optionsTraceability.receivedQuantity ? optionsReports.push("receivedQuantity") : null,
      optionsTraceability.invoice ? optionsReports.push("invoice") : null,
      optionsTraceability.product ? optionsReports.push("product") : null,
      optionsTraceability.social ? optionsReports.push("social") : null,
      optionsTraceability.quality ? optionsReports.push("quality") : null,
      optionsTraceability.quality ? optionsReports.push("qualityPercent") : null,
      optionsTraceability.identifier ? optionsReports.push("identifier") : null,
      optionsTraceability.assessmentResult ? optionsReports.push("assessmentResult") : null,
      optionsTraceability.traceabilityFinalScore ? optionsReports.push("traceabilityFinalScore") : null

    let params = new HttpParams()
      .set('startDate', startDate)
      .set('endDate', endDate)
      .set('productType', productType)
      .set('evaluator',"Assai")

    const httpOptions: any = {
      headers: new HttpHeaders({
        Authorization: token,
      }),
      params,
      responseType: 'arraybuffer'
    };

    return this.http.post(`${environment.api}/movement-service/traceabilityReports`, optionsReports, httpOptions).pipe(
      res => res,
      error => error
    );
  }

  getDatasReceivements(category: string, customerEconomicGroup: string, type: string, startCompletionDate: string, endCompletionDate: string, providerDocument?: any) {
    const item = this.storage.getItem('token');

    const token = item ? JSON.parse(item) : {};

    let http = {};
    let params = new HttpParams()
      .set('customerEconomicGroup', customerEconomicGroup)

    if (providerDocument) {
      params = params.set('providerDocument', providerDocument);
    }

    if (category) {
      params = params.set('category', category);
    }

    if (type) {
      params = params.set('type', type);
    }

    if (startCompletionDate) {
      params = params.set('startCompletionDate', startCompletionDate);
    }

    if (endCompletionDate) {
      params = params.set('endCompletionDate', endCompletionDate);
    }


    if (token) {
      http = {
        headers: new HttpHeaders({
          Authorization: token,
        }),
        params,
      };
    }
    return this.http.get(`${environment.api}/movement-service/receivementsCalculation`, http).pipe(
      res => res,
      error => error
    )
  }

  getListsProviders(category: string, customerEconomicGroup: string, type: string, startCompletionDate: string, SendCompletionDate: string) {
    const item = this.storage.getItem('token');

    const token = item ? JSON.parse(item) : {};

    let http = {};
    let params = new HttpParams()
      .set('customerEconomicGroup', customerEconomicGroup)

    if (category) {
      params = params.set('category', category);
    }

    if (type) {
      params = params.set('type', type);
    }

    if (startCompletionDate) {
      params = params.set('startCompletionDate', startCompletionDate);
    }

    if (SendCompletionDate) {
      params = params.set('SendCompletionDate', SendCompletionDate);
    }


    if (token) {
      http = {
        headers: new HttpHeaders({
          Authorization: token,
        }),
        params,
      };
    }
    return this.http.get(`${environment.api}/auditing-service/list-providers-without-quality-auditing`, http).pipe(
      res => res,
      error => error
    )
  }

  findReceivablesWithIncompleteData(productType: any, startDate: any, endDate: any, providerDocument?: any) {
    const item = this.storage.getItem('token');

    const token = item ? JSON.parse(item) : {};

    let http = {};
    let params = new HttpParams();

    if (providerDocument) {
      params = params.set('providerDocument', providerDocument);
    }

    if (productType) {
      params = params.set('productType', productType);
    }

    if (startDate) {
      params = params.set('startDate', startDate);
    }

    if (endDate) {
      params = params.set('endDate', endDate);
    }


    if (token) {
      http = {
        headers: new HttpHeaders({
          Authorization: token,
        }),
        params,
      };
    }
    return this.http.get(`${environment.api}/errors-entry-flv/find-receivables-with-incomplete-data`, http).pipe(
      res => res,
      error => error
    )
  }

  findReceivablesWithoutIntegration(productType: any, startDate: any, endDate: any, providerDocument?: any) {
    const item = this.storage.getItem('token');

    const token = item ? JSON.parse(item) : {};

    let http = {};
    let params = new HttpParams();

    if (providerDocument) {
      params = params.set('providerDocument', providerDocument);
    }

    if (productType) {
      params = params.set('productType', productType);
    }

    if (startDate) {
      params = params.set('startDate', startDate);
    }

    if (endDate) {
      params = params.set('endDate', endDate);
    }


    if (token) {
      http = {
        headers: new HttpHeaders({
          Authorization: token,
        }),
        params,
      };
    }
    return this.http.get(`${environment.api}/errors-receivement/find-receivables-without-integration`, http).pipe(
      res => res,
      error => error
    )
  }

  listReceivablesWithoutOrigin(category: string, customerEconomicGroup: string, type: string, startCompletionDate: string, endCompletionDate: string, providerDocument?: any) {
    const item = this.storage.getItem('token');

    const token = item ? JSON.parse(item) : {};

    let http = {};
    let params = new HttpParams()
      .set('customerEconomicGroup', customerEconomicGroup)

    if (providerDocument) {
      params = params.set('providerDocument', providerDocument);
    }

    if (category) {
      params = params.set('category', category);
    }

    if (type) {
      params = params.set('type', type);
    }

    if (startCompletionDate) {
      params = params.set('startCompletionDate', startCompletionDate);
    }

    if (endCompletionDate) {
      params = params.set('endCompletionDate', endCompletionDate);
    }


    if (token) {
      http = {
        headers: new HttpHeaders({
          Authorization: token,
        }),
        params,
      };
    }
    return this.http.get(`${environment.api}/movement-service/receivablesWithoutOrigin`, http).pipe(
      res => res,
      error => error
    )
  }

}
