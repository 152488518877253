<div class="content">

  <a [routerLink]="[ isCarrefour ? '/rastreabilidade/bovinos' : '/rastreabilidade']" routerLinkActive="active" class="item" *ngIf="!isAuditing">
    <span class="icon">
      <svg width="43" height="43" viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M21.5 2C10.6961 2 1.9375 10.7586 1.9375 21.5625C1.9375 32.3664 10.6961 41.125 21.5 41.125C32.3039 41.125 41.0625 32.3664 41.0625 21.5625"
          stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
        <path
          d="M21.5 11.7812C19.5655 11.7813 17.6744 12.3549 16.0658 13.4297C14.4573 14.5045 13.2036 16.0321 12.4633 17.8194C11.723 19.6067 11.5293 21.5734 11.9067 23.4707C12.2841 25.3681 13.2157 27.111 14.5836 28.4789C15.9515 29.8468 17.6944 30.7784 19.5918 31.1558C21.4892 31.5332 23.4558 31.3395 25.2431 30.5992C27.0304 29.8589 28.558 28.6052 29.6328 26.9967C30.7076 25.3882 31.2813 23.497 31.2813 21.5625M21.5 21.5625L28.3469 14.7091"
          stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M28.3467 7.89701V14.7156H35.2207L41.0623 8.84688H34.2187V2L28.3467 7.89701Z" stroke="white"
          stroke-width="3" stroke-linejoin="round" />
      </svg>
    </span>
    <span class="text">Rastreabilidade</span></a>


</div>
